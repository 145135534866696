@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,600&display=swap");

.certificateContainer {
  background-color: black;
  border-radius: 15px;
  padding: 2.5vw;
}

.headingCertificateContainer {
  text-align: left;
  color: rgb(224, 224, 224);
  font-size: 2.5vw;
  font-family: "Lora", serif;
  padding-bottom: 3vw;
}

.certificateAnchor {
  text-decoration: none;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

::selection {
  color: #fff;
  background: #6665ee;
}
.certificate-bars {
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.5vw;
}

@media (max-device-width: 480px) {
  .certificateContainer {
    margin-bottom: 2vw;
  }

  .certificate-bars {
    grid-template-columns: 1fr;
    margin-left: 5.5vw;
  }
}
