@import "https://fonts.googleapis.com/css?family=Lily+Script+One";
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,600&display=swap");

.projCard-grid-space {
  margin-bottom: 2.5vw;
}

.projCard {
  font-family: "Heebo";
  height: 13vw;
  width: 15vw;
  font-size: 1.5vw;
  color: white; /**/
  border-radius: 10px;
  padding: 2.1vw 1vw 1vw 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  transition: all, 0.5s;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  margin-left: 1.5vw;
  margin-right: 1.5vw;
}

.projCard:hover {
  transform: rotate(0);
  text-decoration: none;
}

.projCard .projectName {
  margin: 0;
  font-size: 1.2vw;
  text-transform: uppercase;
  font-family: "Lora", serif;
}

.projCard .projectDescription {
  font-size: 1vw;
  font-family: "Open Sans";
  color: rgb(41, 41, 41);
}

.projCard .tags {
  display: flex;
}

.projCard .tags .tag {
  font-size: 0.75em;
  background: rgba(255, 255, 255, 0.7);

  border-radius: 0.3rem;
  padding: 0 0.5em;
  margin-right: 0.5em;
  line-height: 1.5em;
  transition: all, 0.5s;
}

.projCard:hover .tags .tag {
  background: #3c3163;
  color: white;
}

.projCard .duration {
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1vw;
  padding: 1vw;
  opacity: 0.8;
}

.projCard:before,
.projCard:after {
  content: "";
  transform: scale(0);
  transform-origin: top left;
  border-radius: 50%;
  position: absolute;
  left: -50%;
  top: -50%;
  z-index: -5;
  transition: all, 0.5s;
  transition-timing-function: ease-in-out;
}

.projCard:before {
  background: rgb(180, 180, 180);
  width: 230%;
  height: 230%;
}

.projCard:after {
  /*background: white;*/
  background: linear-gradient(135deg, #13f1fc 0%, #0470dc 100%);
  width: 200%;
  height: 200%;
}

.projCard:hover {
  color: #3c3163;
}

.projCard:hover:before,
.projCard:hover:after {
  transform: scale(1);
}

@media (max-device-width: 480px) {
  .projCard {
    height: 18vw;
    width: 25vw;
    font-size: 2.9vw;
  }
  .projCard .projectName {
    font-size: 2.1vw;
    padding-bottom: 1vw;
  }
  .projCard .projectDescription {
    font-size: 1.7vw;
  }
  .projCard .duration {
    font-size: 1.6vw;
  }
}

/* MEDIA QUERIES */
/*


@media screen and (max-width: 500px) {
  .projCard {
    max-width: calc(100vw - 4rem);
  }
}


*/
