@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,600&display=swap");

.skillsContainer {
  background-color: black;
  border-radius: 15px;
  padding: 2.5vw;
  margin-top: 5vh;
  margin-bottom: 1vh;
}

.headingSkillsContainer {
  text-align: left;
  color: rgb(224, 224, 224);
  font-size: 2.5vw;
  font-family: "Lora", serif;
  padding-bottom: 3vw;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

::selection {
  color: #fff;
  background: #6665ee;
}
.skill-bars {
  color: white;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 7vw;
}
