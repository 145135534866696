@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,600&display=swap");

.contributionGraphPart {
  color: white;
  text-align: center;
  margin-top: 12vh;

  padding: 2.5vw;
  border-radius: 15px;
  background-color: black;
}
.headingheadingGit {
  text-align: left;
  color: rgb(224, 224, 224);
  font-size: 2.5vw;
  font-family: "Lora", serif;
}

.githubgraph {
  margin-top: 3vw;
}

@media (max-device-width: 480px) {
}
