@import url("https://fonts.googleapis.com/css2?family=Caveat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Tokyo+Zoo&display=swap");

/**/

/**/

.atCenter {
  text-align: center;
}

.atRight {
  text-align: right;
  margin-right: 5%;
}

.socialAccounts_icons {
  background-color: transparent;
}

.headerPart {
  background-color: /*#0d0d0d*/ /*#698894*/ #242b2e;
  color: white;
  padding-bottom: 6vw;
  width: 100%;
  top: 0;
  left: 0;

  overflow: hidden;
}

/**/
/**/
/**/

.headerPart li {
  position: absolute;
  display: block;
  list-style: none;
  width: 10vw;
  height: 10vw;
  background: rgba(255, 255, 255, 0.13);
  animation: animate 20s linear infinite;
  top: 25vw;
}
.headerPart li:nth-child(1) {
  left: 70.12%;
  width: 11vw;
  height: 11vw;
  animation-delay: 0s;
  animation-duration: 6s;
}
.headerPart li:nth-child(2) {
  left: 80.78%;
  width: 11vw;
  height: 12vw;
  animation-delay: 0s;
  animation-duration: 10s;
}
.headerPart li:nth-child(3) {
  left: 60.46%;
  width: 5vw;
  height: 5vw;
  animation-delay: 0s;
  animation-duration: 13s;
}
.headerPart li:nth-child(4) {
  left: 30.33%;
  width: 2vw;
  height: 2vw;
  animation-delay: 0s;
  animation-duration: 15s;
}
.headerPart li:nth-child(5) {
  left: 45.99%;
  width: 7vw;
  height: 7vw;
  animation-delay: 0s;
  animation-duration: 17s;
}
.headerPart li:nth-child(6) {
  left: 16.66%;
  width: 6vw;
  height: 6vw;
  animation-delay: 0s;
  animation-duration: 9s;
}
@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateY(-800px) rotate(360deg);
    opacity: 0;
  }
}

/**/
/**/
/**/

.name {
  color: white;
  font-size: 6vw;
  font-weight: 550;
  padding-top: 5vw;
  font-family: "Zen Tokyo Zoo", cursive;
  animation: blink 1.5s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.tagline {
  font-family: "Caveat", cursive;
  font-size: 1.7vw;
}

.taglineAnimation {
  overflow: hidden;
  border-right: 0.09em solid white;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 3.125s steps(29), blink-caret 0.7s step-end infinite;
  width: fit-content;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 24%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
}

.designation {
  font-family: "Caveat", cursive;
  font-size: 3vw;
  padding-top: 1.8vw;
}

.iconAnchor {
  position: fixed;
  display: inline-block;
  position: relative;
  height: 2.5vw;
  width: 2.5vw;
  margin-top: 1.5vh;
}

svg {
  height: 100%;
  width: 100%;
}

.icon {
  background: /*#0d0d0d*/ transparent;
  border-radius: 100%;
  color: white;
  fill: white;
  margin-left: 2vw;
}

.icon:hover {
  color: #0d0d0d;
  fill: black;
}

.linkedin:hover {
  fill: #036b9b;
  background: white;
}

.github {
  background: /*#6441a5*/ /*#0d0d0d*/ transparent;
  fill: white;
}

.github:hover {
  background: white;
  fill: /*black*/ #242b2e;
}

.whatsapp {
  background: /*#6441a5*/ /*#0d0d0d*/ transparent;
  fill: white;
}

.whatsapp:hover {
  background-size: 80%;
  background: green;
  fill: /*black*/ white;
}

.instagram {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  fill: /*black*/ rgba(245, 245, 245, 0.856);
}

.instagram:hover {
  background-size: 100%;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  fill: /*black*/ rgba(245, 245, 245, 0.665);
}

@media (max-device-width: 480px) {
  .headerPart {
    padding-bottom: 12vw;
  }
  .headerPart li {
    top: 30.8%;
  }
  .iconAnchor {
    position: fixed;
    display: inline-block;
    position: relative;
    height: 1vw;
    width: 2.5vw;
    margin-top: 0vw;
  }
  .name {
    margin-top: 6vw;
    font-size: 7vw;
    margin-bottom: 0;
  }
  .tagline {
    font-size: 4vw;
  }
  .designation {
    padding-top: 0vw;
    font-size: 5.8vw;
  }

  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 58%;
    }
  }
  .icon {
    margin-left: 5vw;
  }

  a {
    height: 5vw;
    width: 5vw;
    background-size: contain;
  }
}
