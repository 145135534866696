.footerPart {
  font-size: 1.2vw;
  color: #7e7e7e;
  background-color: /*#0d0d0d*/ #242b2e;
  width: 100%;
  height: 7.5vw;
  bottom: 0px;
  position: relative;
  margin-top: auto;
  margin-bottom: 0px;
  text-align: center;
  padding-top: 2.5vw;
  padding-left: 5vw;
  padding-bottom: 5.5vw;
}

.beating-heart {
  display: inline-block;
  animation: pulsateHeart 0.5s infinite;
}

.beating-name {
  display: inline-block;
  animation: pulsateName 2s infinite;
}

@keyframes pulsateHeart {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulsateName {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

.abhinavBhardwaj,
.abhinavBhardwaj:hover {
  text-decoration: none;
}

@media (max-device-width: 480px) {
  .footerPart {
    height: 10vw;
    font-size: 2.5vw;
    padding-top: 2.5vw;
    padding-bottom: 8.5vw;
  }
}
