.skillBar {
  margin: 1.5vw 0;
}

.skillBar:first-child {
  margin-top: 0px;
}

.skillBar .skillInfo {
  margin-bottom: 1vw;
}

.skillBar .skillInfo span {
  font-size: 1.2vw;
  opacity: 0;
  animation: showText 0.5s 1s linear forwards;
}
@keyframes showText {
  100% {
    opacity: 1;
  }
}

.skillBar .progress-line {
  height: 0.4vw;
  width: 100%;
  background: #424242;
  position: relative;
  transform: scaleX(0);
  transform-origin: left;
  border-radius: 10px;
  animation: animateBar 1s cubic-bezier(1, 1.4, 0.5, 1) forwards;
}
@keyframes animateBar {
  100% {
    transform: scaleX(1);
  }
}

.skillBar .progress-line span {
  height: 100%;
  position: absolute;
  border-radius: 10px;
  transform: scaleX(0);
  transform-origin: left;
  background: #6665ee;
  animation: animateBar 1.3s 1s cubic-bezier(1, 1.4, 0.5, 1) forwards;
}

.progress-line span::before {
  position: absolute;
  content: "";
  top: -0.68vw;
  right: 0;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-bottom-width: 0px;
  border-right-width: 0px;
  border-top-color: white;
  opacity: 0;
  animation: showText2 0.5s 1.5s linear forwards;
}
.progress-line span::after {
  position: absolute;
  top: -1.9vw;
  right: 0;
  font-weight: 500;
  background: white;
  color: black;
  padding: 0.1vw 0.45vw;
  font-size: 0.9vw;
  border-radius: 7px;
  opacity: 0;
  animation: showText2 0.5s 1.5s linear forwards;
}
@keyframes showText2 {
  100% {
    opacity: 1;
  }
}

/**/ /**/

/**/
.progress-line.C span::after {
  content: "80%";
}
.progress-line.Embedded_C span::after {
  content: "65%";
}
.progress-line.Python span::after {
  content: "75%";
}
.progress-line.Java span::after {
  content: "80%";
}
/**/

/**/
.progress-line.HTML span::after {
  content: "85%";
}
.progress-line.CSS span::after {
  content: "65%";
}
/**/

/**/
.progress-line.PHP span::after {
  content: "80%";
}
.progress-line.MySQL span::after {
  content: "70%";
}
/**/

/**/
.progress-line.JavaScript span::after {
  content: "50%";
}
.progress-line.jQuery span::after {
  content: "40%";
}
/**/

/**/
.progress-line.ReactJS span::after {
  content: "50%";
}
.progress-line.Express span::after {
  content: "55%";
}
.progress-line.MongoDB span::after {
  content: "70%";
}
/**/

/**/
.progress-line.Figma span::after {
  content: "35%";
}
.progress-line.Git span::after {
  content: "30%";
}
/**/

/**/ /**/

@media (max-device-width: 480px) {
  .aboutMeRight {
    font-size: 1.8vw;
    padding: 5vw 1vw 5vw 1vw;
  }

  .skillBar {
    margin: 4vw 0;
  }
  .skillBar .skillInfo {
    margin-bottom: 2vw;
  }
  .skillBar .skillInfo span {
    font-size: 2.2vw;
  }

  .skillBar .progress-line {
    height: 0.7vw;
    width: 100%;
    background: #424242;
  }
  .progress-line span::before {
    top: -2.5vw;
  }
  .progress-line span::after {
    top: -3.9vw;
    border-radius: 4px;
    padding: 0.3vw 0.65vw;
    font-size: 1.5vw;
  }
}
