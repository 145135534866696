@import url("https://fonts.googleapis.com/css2?family=Uchen&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,600&display=swap");

.aboutMeContainer {
  border-radius: 15px;
  padding: 2.5vw;
  /*  background-color: black;*/
  background-image: linear-gradient(90deg, #242b2e 50%, white 50%);
  margin-top: 12vh;
  margin-bottom: 7vh;
}
.headingAboutMe {
  text-align: left;
  color: rgb(224, 224, 224);
  font-size: 2.5vw;
  font-family: "Lora", serif;
}

.entire {
  display: grid;
  grid-template-columns: auto auto auto;
  margin-top: 3vw;
}

.avtar {
  padding: 0vw;
  height: fit-content;
  width: fit-content;
  border-style: solid;
  border-color: #ffffff #ffffff #363c3e #363c3e;
  border-radius: 100%;
  transform: rotate(-135deg);
  border-width: 0.6vw;
  position: relative;
}

.avtar_img {
  height: 13vw;
  width: 13vw;
  border-radius: 100%;
  transform: rotate(135deg);

  /*box-shadow: 20px 20px 60px #000000;*/
}

.aboutMeLeft {
  font-size: 1.3vw;
  /*background-color: white;*/
  color: white;
  padding: 5vw 1vw 5vw 1vw;
  text-align: justify;
  /*margin-left: 10%;
  margin-right: 10%;*/
  border-radius: 9px;
  margin-right: 3vw;
  font-family: "Uchen", serif;
  /*background: #222829;
  box-shadow: 20px 20px 60px #121617, -20px -20px 60px #364145;*/
}

.aboutMeRight {
  font-size: 1.3vw;
  /*background-color: white;*/
  color: black;
  padding: 5vw 1vw 5vw 1vw;
  text-align: justify;
  font-family: "Uchen", serif;
  /*margin-left: 10%;
  margin-right: 10%;*/
  border-radius: 9px;
  /*background: #222829;
  box-shadow: 20px 20px 60px #121617, -20px -20px 60px #364145;*/
  margin-left: 2vw;
}

@media (min-device-width: 480px) {
  .avtar {
    top: 20%;
    right: 1.5%;
  }
}

@media (max-device-width: 480px) {
  .avtar {
    margin-top: auto;
    margin-bottom: 60%;
  }
  .aboutMeLeft {
    margin-right: 4vw;
    font-size: 1.8vw;
    padding: 5vw 1vw 5vw 1vw;
  }

  .aboutMeRight {
    font-size: 1.8vw;
    padding: 5vw 1vw 5vw 1vw;
  }
}
