@import url("https://fonts.googleapis.com/css2?family=Uchen&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lily+Script+One");

.projectsContainer {
  border-radius: 15px;
  padding: 2.5vw 0vw 2.5vw 1vw;
  background-color: transparent;

  /**/
}

.headingProjectsContainer {
  text-align: left;
  color: rgb(224, 224, 224);
  font-size: 2.5vw;
  font-family: "Lora", serif;
  padding-bottom: 3vw;
  padding-left: 1.5vw;
}
/**/
@import url("https://fonts.googleapis.com/css?family=Heebo:400,700|Open+Sans:400,700");

a {
  color: inherit;
}

.projCards-wrapper {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto auto auto auto;
  margin: 0;
  width: max-content;
}

@media (max-device-width: 480px) {
  .projCards-wrapper {
    grid-template-columns: auto auto auto;
  }
}
/*
@media screen and (max-width: 900px) {
  .projCards-wrapper {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 500px) {
  .projCards-wrapper {
    padding: 4rem 2rem;
  }
}
*/
