.certificateBar {
  background-color: rgb(198, 203, 207);
  border-radius: 1.5vw;
  color: black;
  padding: 1vw 2vw 2.5vw 2vw;
  margin-bottom: 1.2vw;
}

.certificateBar:hover {
  background-color: rgb(238, 238, 238);
  color: black;
}

.certificateBar .certificateInfo {
  font-size: 1.2vw;
  margin-bottom: 0.2vw;
}

.certificateDetails {
  font-size: 0.9vw;
}

.certificateBar .certificateInfo span {
  /*font-size: 1.1vw;*/
  /*opacity: 0;*/
}

.certificateDetails .certificateIssuedBy {
  display: block;
  float: left;
  color: rgb(77, 77, 77);
  font-style: italic;
}
.certificateDetails .certificateIssueDate {
  display: block;
  float: right;
}

@media (max-device-width: 480px) {
  .certificateBar {
    width: 75vw;
    border-radius: 0;
  }

  .temp {
    padding-bottom: 50vw;
  }
}
